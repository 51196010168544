import { Injectable } from "@angular/core";
import { EndpointAPI } from "../../environments/environment.api";

@Injectable({
  providedIn: 'root'
})

export class BaseService {

  /* -- Authentication -- */
  static readonly ENDPOINT_NAME_AUTHENTICATION_CONFIG: string = "login";
  static readonly ENDPOINT_NAME_LOGOUT: string  = "logout";
  static readonly ENDPOINT_NAME_REGISTER_ACCOUNT: string  = "register";
  static readonly ENDPOINT_NAME_REGISTER_CONFIRMATION: string  = "confirmRegister";
  static readonly ENDPOINT_NAME_CHANGE_PASSWORD: string  = "changePassword";
  static readonly ENDPOINT_NAME_RESET_PASSWORD_FOR_EMAIL: string  = "resetPasswordForEmail";
  static readonly ENDPOINT_NAME_GOOGLE_AUTH0_AUTHENTICATION: string  = "googleAuth";

  /* -- Restaurant -- */
  static readonly ENDPOINT_NAME_GET_RANDOM_RESTAURANTS: string = "getRandomRestaurants";
  static readonly ENDPOINT_NAME_GET_RESTAURANT_DETAIL: string  = "getRestaurantDetail";
  static readonly ENDPOINT_NAME_FILTER_RESTAURANT: string  = "filterRestaurants";
    static readonly ENDPOINT_NAME_ADD_TO_FAVORITES_RESTAURANT: string  = "addToFavoritesRestaurant";

  /* -- Dishes -- */
  static readonly ENDPOINT_NAME_GET_RANDOM_DISHES: string  = "getRandomDishes";
  static readonly ENDPOINT_NAME_GET_DISH_DETAIL: string  = "getDishDetail";
  static readonly ENDPOINT_NAME_FILTER_DISHES: string  = "filterDishes";
    static readonly ENDPOINT_NAME_ADD_TO_FAVORITES_DISH: string  = "addToFavoritesDish";

  /* -- Chef -- */
  static readonly ENDPOINT_NAME_GET_RANDOM_CHEFS: string  = "getRandomChefs";
  static readonly ENDPOINT_NAME_GET_CHEF_DETAIL: string  = "getChefDetail";
  static readonly ENDPOINT_NAME_FILTER_CHEFS: string  = "filterChefs";
  static readonly ENDPOINT_NAME_ADD_TO_FAVORITES_CHEF: string  = "addToFavoritesChef";

  /* -- Experience -- */
  static readonly ENDPOINT_NAME_GET_RANDOM_EXPERIENCE: string  = "getRandomExperience";
  static readonly ENDPOINT_NAME_GET_EXPERIENCE_DETAIL: string  = "getExperienceDetail";
  static readonly ENDPOINT_NAME_FILTER_EXPERIENCES: string  = "filterExperiences";
    static readonly ENDPOINT_NAME_ADD_TO_FAVORITES_EXPERIENCE: string  = "addToFavoritesExperience";

  /* -- Events -- */
  static readonly ENDPOINT_NAME_GET_RANDOM_EVENTS: string  = "getRandomEvents";
  static readonly ENDPOINT_NAME_GET_EVENT_DETAIL: string  = "getEventDetail";

  /* -- Languages --*/
  static readonly ENDPOINT_NAME_GET_LANGUAGES: string = "getLanguages";

  /* -- Tags --*/
  static readonly ENDPOINT_NAME_GET_TAG_CATEGORY_BY_ID: string = "getTagsCategoryByID";
  static readonly ENDPOINT_NAME_GET_TAG_CATEGORY_BY_IDS: string = "getTagsInCategories";

  /* -- Tag Category --*/
  static readonly  ENDPOINT_NAME_GET_RESTAURANT_CATEGORY:string = "tagRestaurantCategory";
  static readonly  ENDPOINT_NAME_GET_CHEF_CATEGORY:string = "tagChefCategory";
  static readonly  ENDPOINT_NAME_GET_DISH_CATEGORY:string = "tagDishCategory";
  static readonly  ENDPOINT_NAME_GET_EXPERIENCE_CATEGORY:string = "tagExperienceCategory";

  /* -- Bookings --*/
  static readonly  ENDPOINT_NAME_GET_BOOKING_BY_ID:string = "getBookingByID";
  static readonly  ENDPOINT_NAME_GET_BOOKING_BY_EXPERIENCE:string = "getBookingByExperience";
  static readonly  ENDPOINT_NAME_GET_BOOKING_BY_RESTAURANT:string = "getBookingByRestaurant";
  static readonly  ENDPOINT_NAME_BOOK_EXPERIENCE:string = "bookExperience";
  static readonly  ENDPOINT_NAME_BOOK_RESTAURANT:string = "bookRestaurant";
  static readonly  ENDPOINT_NAME_BOOKING_EXPERIENCE:string = "getExperienceBookings"
  static readonly  ENDPOINT_NAME_BOOKING_RESTAURANT:string = "getRestaurantBookings";
  static readonly  ENDPOINT_NAME_DELETE_BOOKING_RESTAURANT:string = "deleteBookingRestaurant";
  static readonly  ENDPOINT_NAME_DELETE_BOOKING_EXPERIENCE:string = "deleteBookingExperience";

  /* -- Favorites --*/
  static readonly  ENDPOINT_NAME_GET_FAVORITES_RESTAURANT:string = "getFavoritesRestaurant";
  static readonly  ENDPOINT_NAME_GET_FAVORITES_DISH:string = "getFavoriteDishes";
  static readonly  ENDPOINT_NAME_GET_FAVORITES_CHEF:string = "getFavoriteChefs";
  static readonly  ENDPOINT_NAME_GET_FAVORITES_EXPERIENCE:string = "getFavoriteExperiences";
  static readonly  ENDPOINT_NAME_REMOVE_FAVORITES_RESTAURANT:string = "removeFavoriteRestaurant";
  static readonly  ENDPOINT_NAME_REMOVE_FAVORITES_DISH:string = "removeFavoriteDish";
  static readonly  ENDPOINT_NAME_REMOVE_FAVORITES_CHEF:string = "removeFavoriteChef";
  static readonly  ENDPOINT_NAME_REMOVE_FAVORITES_EXPERIENCE:string = "removeFavoriteExperience";


  protected getEndpoint( key: string , ...parameters: any[] ): string {
    let endpoint: string | undefined = EndpointAPI.find( construction=> construction.name == key )?.endpoint;
    for ( let parameter of parameters ) {
      endpoint = endpoint + ( parameter != null ? parameter.toString().trim() : "" );
    }
    return  <string>endpoint;
  }

}
