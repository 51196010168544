import { EndpointModel } from "../app/models/interfaces/endpoint.model";
import { environment } from "./environment";

export const EndpointAPI = [

  /** Authentication **/
  { name: "login" , endpoint: environment.endPoint + "authentication/getauth" },
  { name: "logout" , endpoint: environment.endPoint + "authentication/logout" },
  { name: "register" , endpoint: environment.endPoint + "authentication/register" },
  { name: "confirmRegister" , endpoint: environment.endPoint + "authentication/confirm-registration" },
  { name: "changePassword" , endpoint: environment.endPoint + "authentication/change-password" },
  { name: "resetPasswordForEmail" , endpoint: environment.endPoint + "authentication/reset-password-for-email" },
  { name: "googleAuth" , endpoint: environment.endPoint + "authentication/google-auth" },

  /** Dish **/
  { name: "getRandomDishes" , endpoint: environment.endPoint + "dish/random-dishes" },
  { name: "getDishDetail" , endpoint: environment.endPoint + "dish/dish-detail" },
  { name: "filterDishes" , endpoint: environment.endPoint + "dish/filter-dishes" },
  {name: "addToFavoritesDish", endpoint: environment.endPoint + "dish/add-to-favorites"},

  /** Restaurant **/
  { name: "getRandomRestaurants" , endpoint: environment.endPoint + "restaurant/random-restaurants" },
  { name: "getRestaurantDetail" , endpoint: environment.endPoint + "restaurant/restaurant-detail" },
  { name: "filterRestaurants" , endpoint: environment.endPoint + "restaurant/filter-restaurants" },
  { name: "addToFavoritesRestaurant" , endpoint: environment.endPoint + "restaurant/add-to-favorites" },


  /** Chef **/
  { name: "getRandomChefs" , endpoint: environment.endPoint + "chef/random-chefs" },
  { name: "getChefDetail" , endpoint: environment.endPoint + "chef/chef-detail" },
  { name: "filterChefs" , endpoint: environment.endPoint + "chef/filter-chefs" },
  { name: "addToFavoritesChef" , endpoint: environment.endPoint + "chef/add-to-favorites" },


  /** Experience **/
  { name: "getRandomExperience" , endpoint: environment.endPoint + "experience/random-experiences" },
  { name: "getExperienceDetail" , endpoint: environment.endPoint + "experience/experience-detail" },
  { name: "filterExperiences" , endpoint: environment.endPoint + "experience/filter-experiences" },
  { name: "addToFavoritesExperience" , endpoint: environment.endPoint + "experience/add-to-favorites"},

 /** Event **/
  { name: "getRandomEvents" , endpoint: environment.endPoint + "event/random-events" },
  { name: "getEventDetail" , endpoint: environment.endPoint + "event/event-detail" },

 /** Languages **/
  { name: "getLanguages" , endpoint: environment.endPoint + "language/languages" },


 /** Tag **/
  { name: "getTagsCategoryByID", endpoint: environment.endPoint + "tag/tags-in-category" },
  { name: "getTagsInCategories", endpoint: environment.endPoint + "tag/tags-in-categories" },


 /** Tag Category **/
  { name: "tagRestaurantCategory", endpoint: environment.endPoint + "tag-category/restaurant-tag-categories" },
  { name: "tagChefCategory", endpoint: environment.endPoint + "tag-category/chef-tag-categories" },
  { name: "tagDishCategory", endpoint: environment.endPoint + "tag-category/dish-tag-categories" },
  { name: "tagExperienceCategory", endpoint: environment.endPoint + "tag-category/experience-tag-categories" },

  /** Bookings **/
    { name: "getRestaurantBookings", endpoint: environment.endPoint + "restaurant-booking" },
    { name: "getExperienceBookings", endpoint: environment.endPoint + "experience-booking" },
    { name: "bookingByExperience", endpoint: environment.endPoint + "experience-booking/bookings-by-experience" },
    { name: "bookingByRestaurant", endpoint: environment.endPoint + "restaurant-booking/bookings-by-restaurant" },
    { name: "bookExperience", endpoint: environment.endPoint + "experience-booking/book-experience" },
    { name: "bookRestaurant", endpoint: environment.endPoint + "restaurant-booking/book-restaurant" },
    { name: "deleteBookingExperience", endpoint: environment.endPoint + "experience-booking/booking-experience" },
    { name: "deleteBookingRestaurant", endpoint: environment.endPoint + "restaurant-booking/booking-restaurant" },

    /** Favorites **/
    { name: "getFavoritesRestaurant", endpoint: environment.endPoint + "restaurant/favorite-restaurants" },
    { name: "getFavoriteChefs", endpoint: environment.endPoint + "chef/favorite-chefs" },
    { name: "getFavoriteDishes", endpoint: environment.endPoint + "dish/favorite-dishes" },
    { name: "getFavoriteExperiences", endpoint: environment.endPoint + "experience/favorite-experiences" },
    { name: "removeFavoriteRestaurant", endpoint: environment.endPoint + "restaurant/remove-from-favorites" },
    { name: "removeFavoriteDish", endpoint: environment.endPoint + "dish/remove-from-favorites" },
    { name: "removeFavoriteChef", endpoint: environment.endPoint + "chef/remove-from-favorites" },
    { name: "removeFavoriteExperience", endpoint: environment.endPoint + "experience/remove-from-favorites" },

] as EndpointModel[];
